import { getExploreName } from '../utils/platform';

export const devConfig = {
  sdkKey: 'sGlJoyNdGdl0GslZ6ieL9tcU3OU2btfHfjYb',
  sdkSecret: 'mGiZR7Md4y0wDCwRznvo69EPavLBmMefJQ1x',
  webEndpoint: 'zoom.us',
  topic: 'EfitoSolutions',
  name: `${getExploreName()}-${Math.floor(Math.random() * 1000)}`,
  password: 'abc123',
  signature: '',
  sessionKey: '',
  userIdentity: '',
  // role = 1 to join as host, 0 to join as attendee. The first user must join as host to start the session
  role: 1
};
