import React, { useState, useContext, useRef, useEffect, useCallback, Suspense } from 'react';
import classnames from 'classnames';
import _ from 'lodash';
import { RouteComponentProps } from 'react-router-dom';
import ZoomContext from '../../context/zoom-context';
import ZoomMediaContext from '../../context/media-context';
import Avatar from './components/avatar';
import VideoFooter from './components/video-footer';
import Pagination from './components/pagination';
import { useCanvasDimension } from './hooks/useCanvasDimension';
import { useGalleryLayout } from './hooks/useGalleryLayout';
import { usePagination } from './hooks/usePagination';
import { useActiveVideo } from './hooks/useAvtiveVideo';
import { useShare } from './hooks/useShare';
import { useLocalVolume } from './hooks/useLocalVolume';
import './video.scss';
import { isSupportWebCodecs } from '../../utils/platform';
import { isShallowEqual } from '../../utils/util';
import { useSizeCallback } from '../../hooks/useSizeCallback';
import { useAdvancedFeatureSwitch } from './hooks/useAdvancedFeatureSwith';
import RemoteControlPanel, { RemoteControlIndication } from './components/remote-control';
import { useCameraControl } from './hooks/useCameraControl';
import { useNetworkQuality } from './hooks/useNetworkQuality';
import { FaceLandmarker, FaceLandmarkerOptions, FilesetResolver } from "@mediapipe/tasks-vision";
import { Color, Euler, Matrix4 } from 'three';
import { Canvas, useFrame, useGraph } from '@react-three/fiber';
import { useGLTF } from '@react-three/drei';


let video: HTMLVideoElement;
let faceLandmarker: FaceLandmarker;
let lastVideoTime = -1;
let blendshapes: any[] = [];
let rotation: Euler;
let headMesh: any[] = [];
const urlArray = [
  "https://api.readyplayer.me/v1/avatars/632d65e99b4c6a4352a9b8db.glb",
  "https://api.readyplayer.me/v1/avatars/632d65e99b4c6a4352a9b8db.glb",
  "https://api.readyplayer.me/v1/avatars/632d65e99b4c6a4352a9b8db.glb",
  "https://api.readyplayer.me/v1/avatars/632d65e99b4c6a4352a9b8db.glb",
  "https://api.readyplayer.me/v1/avatars/632d65e99b4c6a4352a9b8db.glb",
  "https://api.readyplayer.me/v1/avatars/632d65e99b4c6a4352a9b8db.glb",
  "https://api.readyplayer.me/v1/avatars/632d65e99b4c6a4352a9b8db.glb",
  "https://api.readyplayer.me/v1/avatars/632d65e99b4c6a4352a9b8db.glb",
  "https://api.readyplayer.me/v1/avatars/632d65e99b4c6a4352a9b8db.glb",
  "https://api.readyplayer.me/v1/avatars/632d65e99b4c6a4352a9b8db.glb",
];
const num = Math.floor(Math.random() * 8);

const options: FaceLandmarkerOptions = {
  baseOptions: {
    modelAssetPath: `https://storage.googleapis.com/mediapipe-models/face_landmarker/face_landmarker/float16/1/face_landmarker.task`,
    delegate: "GPU"
  },
  numFaces: 1,
  runningMode: "VIDEO",
  outputFaceBlendshapes: true,
  outputFacialTransformationMatrixes: true,
};

function Avatar2({ url }: { url: string }) {
  const { scene } = useGLTF(url);
  const { nodes } = useGraph(scene);

  useEffect(() => {
    if (nodes.Wolf3D_Head) headMesh.push(nodes.Wolf3D_Head);
    if (nodes.Wolf3D_Teeth) headMesh.push(nodes.Wolf3D_Teeth);
    if (nodes.Wolf3D_Beard) headMesh.push(nodes.Wolf3D_Beard);
    if (nodes.Wolf3D_Avatar) headMesh.push(nodes.Wolf3D_Avatar);
    if (nodes.Wolf3D_Head_Custom) headMesh.push(nodes.Wolf3D_Head_Custom);
  }, [nodes, url]);

  useFrame(() => {
    if (blendshapes.length > 0) {
      blendshapes.forEach(element => {
        headMesh.forEach(mesh => {
          let index = mesh.morphTargetDictionary[element.categoryName];
          if (index >= 0) {
            mesh.morphTargetInfluences[index] = element.score;
          }
        });
      });

      nodes.Head.rotation.set(rotation.x, rotation.y, rotation.z);
      nodes.Neck.rotation.set(rotation.x / 5 + 0.3, rotation.y / 5, rotation.z / 5);
      nodes.Spine2.rotation.set(rotation.x / 10, rotation.y / 10, rotation.z / 10);
    }
  });

  return <primitive object={scene} position={[0, -1.75, 3]} />
}

const VideoContainer: React.FunctionComponent<RouteComponentProps> = (props) => {
  const zmClient = useContext(ZoomContext);
  const {
    mediaStream,
    video: { decode: isVideoDecodeReady }
  } = useContext(ZoomMediaContext);
  const videoRef = useRef<HTMLCanvasElement | null>(null);
  const shareRef = useRef<HTMLCanvasElement | null>(null);
  const selfShareRef = useRef<(HTMLCanvasElement & HTMLVideoElement) | null>(null);
  const shareContainerRef = useRef<HTMLDivElement | null>(null);
  const [containerDimension, setContainerDimension] = useState({ width: 0, height: 0 });
  const [shareViewDimension, setShareViewDimension] = useState({ width: 0, height: 0 });
  const canvasDimension = useCanvasDimension(mediaStream, videoRef);
  const activeVideo = useActiveVideo(zmClient);
  const { page, pageSize, totalPage, totalSize, setPage } = usePagination(zmClient, canvasDimension);
  const { visibleParticipants, layout: videoLayout } = useGalleryLayout(
    zmClient,
    mediaStream,
    isVideoDecodeReady,
    videoRef,
    canvasDimension,
    {
      page,
      pageSize,
      totalPage,
      totalSize
    }
  );

  const { isRecieveSharing, isStartedShare, sharedContentDimension } = useShare(zmClient, mediaStream, shareRef);

  const { userVolumeList, setLocalVolume } = useLocalVolume();
  const {
    isControllingFarEnd,
    currentControlledUser,
    isInControl,
    giveUpControl,
    stopControl,
    turnDown,
    turnRight,
    turnLeft,
    turnUp,
    zoomIn,
    zoomOut,
    switchCamera
  } = useCameraControl(zmClient, mediaStream);

  const { advancedSwitch, toggleAdjustVolume, toggleFarEndCameraControl } = useAdvancedFeatureSwitch(
    zmClient,
    mediaStream,
    visibleParticipants
  );
  const networkQuality = useNetworkQuality(zmClient);

  const isSharing = isRecieveSharing || isStartedShare;




  // Ready Player Me
  const [url, setUrl] = useState<string>("https://models.readyplayer.me/6460d95f9ae10f45bffb2864.glb?morphTargets=ARKit&textureAtlas=1024");
  
  const setup = async () => {
    const filesetResolver = await FilesetResolver.forVisionTasks("https://cdn.jsdelivr.net/npm/@mediapipe/tasks-vision@0.10.0/wasm");
    faceLandmarker = await FaceLandmarker.createFromOptions(filesetResolver, options);

    video = document.getElementById("video") as HTMLVideoElement;
    
    navigator.mediaDevices.getUserMedia({
      video: { width: 1280, height: 720 },
      audio: false,
    }).then(function (stream) {
      video.srcObject = stream;
      video.addEventListener("loadeddata", predict);
    });
  }

  const predict = async () => {
    let nowInMs = Date.now();
    if (lastVideoTime !== video.currentTime) {
      lastVideoTime = video.currentTime;
      const faceLandmarkerResult = faceLandmarker.detectForVideo(video, nowInMs);

      if (faceLandmarkerResult.faceBlendshapes && faceLandmarkerResult.faceBlendshapes.length > 0 && faceLandmarkerResult.faceBlendshapes[0].categories) {
        blendshapes = faceLandmarkerResult.faceBlendshapes[0].categories;

        const matrix = new Matrix4().fromArray(faceLandmarkerResult.facialTransformationMatrixes![0].data);
        rotation = new Euler().setFromRotationMatrix(matrix);
      }
    }

    window.requestAnimationFrame(predict);
  }

  useEffect(() => {
    // setup();
  }, []);

  const btnClick = () => {
    setup();
  }







  useEffect(() => {
    if (isSharing && shareContainerRef.current) {
      const { width, height } = sharedContentDimension;
      const { width: containerWidth, height: containerHeight } = containerDimension;
      const ratio = Math.min(containerWidth / width, containerHeight / height, 1);
      setShareViewDimension({
        width: Math.floor(width * ratio),
        height: Math.floor(height * ratio)
      });
    }
  }, [isSharing, sharedContentDimension, containerDimension]);

  const onShareContainerResize = useCallback(({ width, height } : any) => {
    _.throttle(() => {
      setContainerDimension({width, height});
    }, 50)();
  }, []);
  
  useSizeCallback(shareContainerRef.current, onShareContainerResize);
  
  useEffect(() => {
    if (!isShallowEqual(shareViewDimension, sharedContentDimension)) {
      mediaStream?.updateSharingCanvasDimension(shareViewDimension.width, shareViewDimension.height);
    }
  }, [mediaStream, sharedContentDimension, shareViewDimension]);

  const onAdvancedFeatureToggle = useCallback(
    (userId: number, key: string) => {
      if (key === 'volume') {
        toggleAdjustVolume(userId);
      } else if (key === 'farend') {
        if (isControllingFarEnd) {
          giveUpControl();
        } else {
          mediaStream?.requestFarEndCameraControl(userId);
        }
        // toggleFarEndCameraControl(userId);
      }
    },
    [toggleAdjustVolume, giveUpControl, mediaStream, isControllingFarEnd]
  );
  return (
    <div className="viewport">
      <div
        className={classnames('share-container', {
          'in-sharing': isSharing
        })}
        ref={shareContainerRef}
      >
        <div
          className="share-container-viewport"
          style={{
            width: `${shareViewDimension.width}px`,
            height: `${shareViewDimension.height}px`
          }}
        >
          <canvas className={classnames('share-canvas', { hidden: isStartedShare })} ref={shareRef} />
          {mediaStream?.isStartShareScreenWithVideoElement() ? (
            <video
              className={classnames('share-canvas', {
                hidden: isSharing || isRecieveSharing
              })}
              ref={selfShareRef}
            />
          ) : (
            <canvas
              className={classnames('share-canvas', {
                hidden: isRecieveSharing
              })}
              ref={selfShareRef}
            />
          )}
        </div>
      </div>

      <div className={classnames('video-container', { 'in-sharing': isSharing })} >
        <canvas className="video-canvas" id="video-canvas" width="800" height="600" ref={videoRef} />

        {/* <div className="video-canvas" id="video-canvas"></div> */}
        
        <ul className="avatar-list">
          {        
          

          visibleParticipants.map((user, index) => {
            if (index > videoLayout.length - 1) {
              return null;
            }
            const dimension = videoLayout[index];
            const { width, height, x, y } = dimension;
            const { height: canvasHeight } = canvasDimension;

            return (
              <Avatar
                participant={user}
                key={user.userId}
                isActive={activeVideo === user.userId}
                volume={userVolumeList.find((u) => u.userId === user.userId)?.volume}
                setLocalVolume={setLocalVolume}
                advancedFeature={advancedSwitch[`${user.userId}`]}
                onAdvancedFeatureToggle={onAdvancedFeatureToggle}
                isUserCameraControlled={isControllingFarEnd}
                networkQuality={networkQuality[`${user.userId}`]}
                style={{
                  width: `${width}px`,
                  height: `${height}px`,
                  top: `${canvasHeight - y - height}px`,
                  left: `${x}px`
                }}
                url={urlArray[num]}
                url2={urlArray[num+1]}
                rand={index}
              />
            );
          })}
        </ul>
      </div>

      <VideoFooter className="video-operations" sharing shareRef={selfShareRef} />

      {isControllingFarEnd && (
        <RemoteControlPanel
          turnDown={turnDown}
          turnLeft={turnLeft}
          turnRight={turnRight}
          turnUp={turnUp}
          zoomIn={zoomIn}
          zoomOut={zoomOut}
          switchCamera={switchCamera}
          controlledName={currentControlledUser.displayName}
        />
      )}

      {isInControl && <RemoteControlIndication stopCameraControl={stopControl} />}
      {totalPage > 1 && <Pagination page={page} totalPage={totalPage} setPage={setPage} inSharing={isSharing} />}

      {/* <div className="App">

        <button onClick={btnClick}>Open Camera</button>

        <video className='camera-feed' id="video" autoPlay></video>

        <Suspense>
          <Canvas style={{ height: 600 }} camera={{ fov: 25 }} shadows>
            <ambientLight intensity={0.5} />
            <pointLight position={[10, 10, 10]} color={new Color(1, 1, 0)} intensity={0.5} castShadow />
            <pointLight position={[-10, 0, 10]} color={new Color(1, 0, 0)} intensity={0.5} castShadow />
            <pointLight position={[0, 0, 10]} intensity={0.5} castShadow />
            <Avatar2 url={url} />
          </Canvas>
        </Suspense>
      </div> */}
      
    </div>

    
  );
};

export default VideoContainer;
