import React, { useContext, useRef, useState, useCallback, useEffect, useMemo } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { VideoQuality } from '@zoom/videosdk';
import classnames from 'classnames';
import _ from 'lodash';
import ZoomContext from '../../context/zoom-context';
import ZoomMediaContext from '../../context/media-context';
import Avatar from './components/avatar';
import VideoFooter from './components/video-footer';
import { useShare } from './hooks/useShare';
import { useParticipantsChange } from './hooks/useParticipantsChange';
import { useCanvasDimension } from './hooks/useCanvasDimension';
import { useMount, useSizeCallback } from '../../hooks';
import { Participant } from '../../index-types';
import { SELF_VIDEO_ID } from './video-constants';
import { isShallowEqual } from '../../utils/util';
import { useLocalVolume } from './hooks/useLocalVolume';
import './video.scss';
import { useNetworkQuality } from './hooks/useNetworkQuality';
import videoButtonContext from '../../context/video-button-context';
import { FaceLandmarker, FaceLandmarkerOptions, FilesetResolver } from '@mediapipe/tasks-vision';
import { Color, Euler, Matrix4 } from 'three';
import { useGLTF } from '@react-three/drei';
import { Canvas, useFrame, useGraph } from '@react-three/fiber';


const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)'
  },
  overlay: {
    background: "#000"
  }
};

let video: HTMLVideoElement;
let faceLandmarker: FaceLandmarker;
let lastVideoTime = -1;
let blendshapes: any[] = [];
let rotation: Euler;
let headMesh: any[] = [];

const options: FaceLandmarkerOptions = {
  baseOptions: {
    modelAssetPath: `https://storage.googleapis.com/mediapipe-models/face_landmarker/face_landmarker/float16/1/face_landmarker.task`,
    delegate: "GPU"
  },
  numFaces: 1,
  runningMode: "VIDEO",
  outputFaceBlendshapes: true,
  outputFacialTransformationMatrixes: true,
};


function Avatar2({ url }: { url: string }) {
  const { scene } = useGLTF(url);
  const { nodes } = useGraph(scene);

  useEffect(() => {
    if (nodes.Wolf3D_Head) headMesh.push(nodes.Wolf3D_Head);
    if (nodes.Wolf3D_Teeth) headMesh.push(nodes.Wolf3D_Teeth);
    if (nodes.Wolf3D_Beard) headMesh.push(nodes.Wolf3D_Beard);
    if (nodes.Wolf3D_Avatar) headMesh.push(nodes.Wolf3D_Avatar);
    if (nodes.Wolf3D_Head_Custom) headMesh.push(nodes.Wolf3D_Head_Custom);
  }, [nodes, url]);

  useFrame(() => {
    if (blendshapes.length > 0) {
      blendshapes.forEach(element => {
        headMesh.forEach(mesh => {
          let index = mesh.morphTargetDictionary[element.categoryName];

          if (index >= 0) {
            mesh.morphTargetInfluences[index] = element.score;
          }
        });
      });

      nodes.Head.rotation.set(rotation.x, rotation.y, rotation.z);
      nodes.Neck.rotation.set(rotation.x / 5 + 0.3, rotation.y / 5, rotation.z / 5);
      nodes.Spine2.rotation.set(rotation.x / 10, rotation.y / 10, rotation.z / 10);
    }
  });

  return <primitive object={scene} position={[0, -1.75, 3]} />
}

const VideoContainer: React.FunctionComponent<RouteComponentProps> = (props) => {
  const zmClient = useContext(ZoomContext);
  const {
    mediaStream,
    video: { decode: isVideoDecodeReady }
  } = useContext(ZoomMediaContext);
  const videoRef = useRef<HTMLCanvasElement | null>(null);
  const shareRef = useRef<HTMLCanvasElement | null>(null);
  const selfShareRef = useRef<HTMLCanvasElement & HTMLVideoElement>(null);
  const shareContainerRef = useRef<HTMLDivElement | null>(null);
  const [participants, setParticipants] = useState<Participant[]>([]);
  const [activeVideo, setActiveVideo] = useState<number>(0);
  const previousActiveUser = useRef<Participant>();
  const canvasDimension = useCanvasDimension(mediaStream, videoRef);
  const { isRecieveSharing, isStartedShare, sharedContentDimension } = useShare(zmClient, mediaStream, shareRef);
  const isSharing = isRecieveSharing || isStartedShare;
  const [containerDimension, setContainerDimension] = useState({
    width: 0,
    height: 0
  });
  const [shareViewDimension, setShareViewDimension] = useState({
    width: 0,
    height: 0
  });
  const { userVolumeList, setLocalVolume } = useLocalVolume();
  const networkQuality = useNetworkQuality(zmClient);
  const [mediaStream2, setMediaStream2] = useState<any>(null);
  const [cameraButtonClick, setCameraButtonClick] = useContext(videoButtonContext);
  const [isPredictFunctionRun, setIsPredictFunctionRun] = useState<boolean>(false)
  const [url, setUrl] = useState<string>("https://api.readyplayer.me/v1/avatars/632d65e99b4c6a4352a9b8db.glb");
  const cameraElementRef = useRef<HTMLVideoElement | null>(null);

  useEffect(() => {
    mediaStream!.isBrowserSupportPTZ();
  }, [])

  useEffect(()=>{
    startCapture()
}, [cameraButtonClick])

  const startCapture = async () => {
    try {
      const canvas = videoRef.current;
      if (canvas){
      const stream = canvas?.captureStream();
      console.log(stream);
       // Convert canvas to media stream
      setMediaStream2(stream);
      }
    } catch (error) {
      console.error('Error capturing canvas:', error);
    }
  };

  const predict = async () => {
    try {
      let nowInMs = Date.now();
    video = document.getElementById('camera-view-avatar') as HTMLVideoElement;
    if (video != null) {
      if (lastVideoTime !== video.currentTime) {
        
        lastVideoTime = video?.currentTime;
        const faceLandmarkerResult = faceLandmarker?.detectForVideo(video, nowInMs);
  
        if (faceLandmarkerResult.faceBlendshapes && faceLandmarkerResult.faceBlendshapes.length > 0 && faceLandmarkerResult.faceBlendshapes[0].categories) {
          blendshapes = faceLandmarkerResult.faceBlendshapes[0].categories;
  
          const matrix = new Matrix4().fromArray(faceLandmarkerResult.facialTransformationMatrixes![0].data);
          rotation = new Euler().setFromRotationMatrix(matrix);
        }
        
      } else {
        // console.log('CCC');
      }
      window.requestAnimationFrame(predict);

    } else {
      // console.log('DDDD');
    }
    } catch (error) {
      console.log(error);
      
    }
    
    
  }

  useParticipantsChange(zmClient, (payload) => {
    console.log(payload);
    
    setParticipants(payload);
  });
  const onActiveVideoChange = useCallback((payload: { userId: any }) => {
    const { userId } = payload;
    setActiveVideo(userId);
  }, []);
  useEffect(() => {
    zmClient.on('video-active-change', onActiveVideoChange);
    return () => {
      zmClient.off('video-active-change', onActiveVideoChange);
    };
  }, [zmClient, onActiveVideoChange]);

  const activeUser = useMemo(
    () => participants.find((user) => user.userId === activeVideo),
    [participants, activeVideo]
  );
  const isCurrentUserStartedVideo = zmClient.getCurrentUserInfo()?.bVideoOn;
  useEffect(() => {
    console.log("Single video");
    
    if (mediaStream && videoRef.current && isVideoDecodeReady) {
      if (activeUser?.bVideoOn !== previousActiveUser.current?.bVideoOn) {
        if (activeUser?.bVideoOn) {
          setIsPredictFunctionRun(true)
          mediaStream.renderVideo(
            videoRef.current,
            activeUser.userId,
            canvasDimension.width,
            canvasDimension.height,
            0,
            0,
            VideoQuality.Video_360P as any
          ).then(() => {
            fetchData();
          });
        } else {
          setIsPredictFunctionRun(false)
          if (previousActiveUser.current?.bVideoOn) {
            mediaStream.stopRenderVideo(videoRef.current, previousActiveUser.current?.userId);
          }
        }
      }
      if (
        activeUser?.bVideoOn &&
        previousActiveUser.current?.bVideoOn &&
        activeUser.userId !== previousActiveUser.current.userId
      ) {
        mediaStream.stopRenderVideo(videoRef.current, previousActiveUser.current?.userId);
        mediaStream.renderVideo(
          videoRef.current,
          activeUser.userId,
          canvasDimension.width,
          canvasDimension.height,
          0,
          0,
          VideoQuality.Video_360P as any
        );
      }
      previousActiveUser.current = activeUser;
    }
  }, [mediaStream, activeUser, isVideoDecodeReady, canvasDimension]);

  async function fetchData() {
    try {
      const filesetResolver = await FilesetResolver.forVisionTasks("https://cdn.jsdelivr.net/npm/@mediapipe/tasks-vision@0.10.0/wasm");
      faceLandmarker = await FaceLandmarker.createFromOptions(filesetResolver, options);
      
      video = document.getElementById('camera-view-avatar') as HTMLVideoElement;
      
        videoRef.current?.captureStream().getTracks().forEach(track => {
          if (video != null) {
            video.srcObject = (new MediaStream([track]))
            video.addEventListener("loadeddata", predict);
            // video.play();
            console.log("Video is played ");
            
          }
        });
    } catch (error) {
      console.log(error);
      
    }
     
    
  }

  useMount(() => {
    if (mediaStream) {
      setActiveVideo(mediaStream.getActiveVideoId());
    }
  });
  useEffect(() => {
    if (isSharing && shareContainerRef.current) {
      const { width, height } = sharedContentDimension;
      const { width: containerWidth, height: containerHeight } = containerDimension;
      const ratio = Math.min(containerWidth / width, containerHeight / height, 1);
      setShareViewDimension({
        width: Math.floor(width * ratio),
        height: Math.floor(height * ratio)
      });
    }
  }, [isSharing, sharedContentDimension, containerDimension]);

  const onShareContainerResize = useCallback(({ width, height }:  { width: number; height: number }) => {
    _.throttle(() => {
      setContainerDimension({ width, height });
    }, 50)();
  }, []);
  useSizeCallback(shareContainerRef.current, onShareContainerResize);
  useEffect(() => {
    if (!isShallowEqual(shareViewDimension, sharedContentDimension)) {
      mediaStream?.updateSharingCanvasDimension(shareViewDimension.width, shareViewDimension.height);
    }
  }, [mediaStream, sharedContentDimension, shareViewDimension]);
  return (
    <div className="viewport">
      <div className={classnames('share-container', { 'in-sharing': isSharing })} ref={shareContainerRef} >
        <div className="share-container-viewport" style={{ width: `${shareViewDimension.width}px`, height: `${shareViewDimension.height}px` }} >
          <canvas className={classnames('share-canvas', { hidden: isStartedShare })} ref={shareRef} />
          {mediaStream?.isStartShareScreenWithVideoElement() ? (
            <video className={classnames('share-canvas', { hidden: isRecieveSharing })} ref={selfShareRef} />
          ) : (
            <canvas className={classnames('share-canvas', { hidden: isRecieveSharing })} ref={selfShareRef} />
          )}
        </div>
      </div>
      <div className={classnames('video-container', { 'in-sharing': isSharing })}  >

        <canvas className="video-canvas" id="video-canvas"  ref={videoRef} />
        {mediaStream?.isRenderSelfViewWithVideoElement() ? (
          <video id={SELF_VIDEO_ID} className={classnames('self-video', { 'single-self-video': participants.length === 1, 'self-video-show': isCurrentUserStartedVideo })}/>
        ) : (
          <canvas
            id={SELF_VIDEO_ID}
            width="254"
            height="143"
            className={classnames('self-video', {
              'single-self-video': participants.length === 1,
              'self-video-show': isCurrentUserStartedVideo
            })}
          />
        )}
        {isPredictFunctionRun && <video id='camera-view-avatar' autoPlay ref={cameraElementRef} style={{width: '20%', height: '20%'}} playsInline/>}
            <Canvas camera={{ fov: 15 }} shadows style={{height: '50%'}}>
              <ambientLight intensity={0.5} />
              <pointLight position={[10, 10, 10]} color={new Color(1, 1, 0)} intensity={0.5} castShadow />
              <pointLight position={[-10, 0, 10]} color={new Color(1, 0, 0)} intensity={0.5} castShadow />
              <pointLight position={[0, 0, 10]} intensity={0.5} castShadow />
              <Avatar2 url={url} />
            </Canvas>
      </div>
      <VideoFooter className="video-operations" sharing shareRef={selfShareRef} />
    </div>
  );
};

export default VideoContainer;
