import React, { useContext, useRef, useState, useCallback, useEffect } from 'react';
import { AudioMutedOutlined, CheckOutlined, MoreOutlined } from '@ant-design/icons';
import { Slider, Dropdown, Button } from 'antd';
import type { NetworkQuality } from '@zoom/videosdk';
import { IconFont } from '../../../component/icon-font';
import classNames from 'classnames';
import './avatar.scss';
import { Participant } from '../../../index-types';
import ZoomContext from '../../../context/zoom-context';
import { useHover } from '../../../hooks';
import type { AdvancedFeatureSwitch } from '../video-types';
import { getAntdDropdownMenu, getAntdItem } from './video-footer-utils';
import { FaceLandmarker, FaceLandmarkerOptions, FilesetResolver } from "@mediapipe/tasks-vision";
import { Color, Euler, Matrix4 } from 'three';
import { Canvas, useFrame, useGraph } from '@react-three/fiber';
import { useGLTF } from '@react-three/drei';

let video: HTMLVideoElement;
let faceLandmarker: FaceLandmarker;
let lastVideoTime = -1;
let blendshapes: any[] = [];
let rotation: Euler;
let headMesh: any[] = [];

interface AvatarProps {
  participant: Participant;
  style?: { [key: string]: string };
  isActive: boolean;
  className?: string;
  volume?: number;
  isUserCameraControlled?: boolean;
  advancedFeature?: AdvancedFeatureSwitch;
  networkQuality?: NetworkQuality;
  onAdvancedFeatureToggle?: (userId: number, key: string) => void;
  setLocalVolume?: (userId: number, volume: number) => void;
  url: string;
  url2: string;
  rand: number;
}

function Avatar2({ url }: { url: string }) {
  const { scene } = useGLTF('https://api.readyplayer.me/v1/avatars/632d65e99b4c6a4352a9b8db.glb?morphTargets=ARKit&textureAtlas=1024');
  const { nodes } = useGraph(scene);

  useEffect(() => {
    if (nodes.Wolf3D_Head) headMesh.push(nodes.Wolf3D_Head);
    if (nodes.Wolf3D_Teeth) headMesh.push(nodes.Wolf3D_Teeth);
    if (nodes.Wolf3D_Beard) headMesh.push(nodes.Wolf3D_Beard);
    if (nodes.Wolf3D_Avatar) headMesh.push(nodes.Wolf3D_Avatar);
    if (nodes.Wolf3D_Head_Custom) headMesh.push(nodes.Wolf3D_Head_Custom);
  }, [nodes, url]);

  useFrame(() => {
    if (blendshapes.length > 0) {
      blendshapes.forEach(element => {
        headMesh.forEach(mesh => {
          let index = mesh.morphTargetDictionary[element.categoryName];

          if (index >= 0) {
            mesh.morphTargetInfluences[index] = element.score;
          }
        });
      });

      nodes.Head.rotation.set(rotation.x, rotation.y, rotation.z);
      nodes.Neck.rotation.set(rotation.x / 5 + 0.3, rotation.y / 5, rotation.z / 5);
      nodes.Spine2.rotation.set(rotation.x / 10, rotation.y / 10, rotation.z / 10);
    }
  });

  return <primitive object={scene} position={[0, -1.75, 3]} />
}

function Avatar3({ url }: { url: string }) {
  const { scene } = useGLTF('https://models.readyplayer.me/6460d95f9ae10f45bffb2864.glb?morphTargets=ARKit&textureAtlas=1024');
  const { nodes } = useGraph(scene);

  useEffect(() => {
    if (nodes.Wolf3D_Head) headMesh.push(nodes.Wolf3D_Head);
    if (nodes.Wolf3D_Teeth) headMesh.push(nodes.Wolf3D_Teeth);
    if (nodes.Wolf3D_Beard) headMesh.push(nodes.Wolf3D_Beard);
    if (nodes.Wolf3D_Avatar) headMesh.push(nodes.Wolf3D_Avatar);
    if (nodes.Wolf3D_Head_Custom) headMesh.push(nodes.Wolf3D_Head_Custom);
  }, [nodes, url]);

  useFrame(() => {
    if (blendshapes.length > 0) {
      blendshapes.forEach(element => {
        headMesh.forEach(mesh => {
          let index = mesh.morphTargetDictionary[element.categoryName];

          if (index >= 0) {
            mesh.morphTargetInfluences[index] = element.score;
          }
        });
      });

      nodes.Head.rotation.set(rotation.x, rotation.y, rotation.z);
      nodes.Neck.rotation.set(rotation.x / 5 + 0.3, rotation.y / 5, rotation.z / 5);
      nodes.Spine2.rotation.set(rotation.x / 10, rotation.y / 10, rotation.z / 10);
    }
  });

  return <primitive object={scene} position={[0, -1.75, 3]} />
}

const networkQualityIcons = ['bad', 'bad', 'normal', 'good', 'good', 'good'];
const Avatar = (props: AvatarProps) => {
  const {
    participant,
    style,
    isActive,
    className,
    volume,
    advancedFeature,
    isUserCameraControlled,
    networkQuality,
    setLocalVolume,
    onAdvancedFeatureToggle,
    url,
    url2,
    rand
  } = props;

  // const [url, setUrl] = useState<string>("https://models.readyplayer.me/6460d95f9ae10f45bffb2864.glb?morphTargets=ARKit&textureAtlas=1024");

  const [isDropdownVisible, setIsDropdownVisbile] = useState(false);
  const { displayName, audio, muted, bVideoOn, userId } = participant;
  const avatarRef = useRef(null);
  const isHover = useHover(avatarRef);
  const zmClient = useContext(ZoomContext);
  const onSliderChange = (value: number) => {
    setLocalVolume?.(userId, value);
  };

  const menu = [];

  if (advancedFeature?.adjustVolumn.enabled) {
    menu.push(
      getAntdItem('Adjust volume locally', 'volume', advancedFeature?.adjustVolumn.toggled && <CheckOutlined />)
    );
  }

  if (advancedFeature?.farEndCameraControl.enabled) {
    menu.push(getAntdItem(isUserCameraControlled ? 'Give up camera control' : 'Control far end camera', 'farend'));
  }

  const onDropDownVisibleChange = useCallback((visible: any) => {
    setIsDropdownVisbile(visible);
  }, []);

  const onMenuItemClick = ({ key }: { key: string }) => {
    onAdvancedFeatureToggle?.(userId, key);
    setIsDropdownVisbile(false);
  };

  return (
    <div
      className={classNames('avatar', { 'avatar-active': isActive }, className)}
      style={{ ...style, background: bVideoOn ? 'transparent' : 'rgb(26,26,26)' }}
      ref={avatarRef}
    >
      {/* {(bVideoOn || (audio === 'computer' && muted)) && (
        <div className="corner-name">
          {audio === 'computer' && muted && <AudioMutedOutlined style={{ color: '#f00' }} />}
          {bVideoOn && networkQuality !== undefined && (
            <IconFont
              type={`icon-network-${
                networkQualityIcons[
                  Math.min(networkQuality?.downlink ?? Number.MAX_VALUE, networkQuality?.uplink ?? Number.MAX_VALUE)
                ]
              }`}
            />
          )}
          {bVideoOn && <span>{displayName}</span>}
        </div>
      )}
      {!bVideoOn && <p className="center-name">{displayName}</p>}
      {menu.length > 0 && (
        <Dropdown
          menu={getAntdDropdownMenu(menu, onMenuItemClick)}
          placement="bottomRight"
          trigger={['click']}
          onOpenChange={onDropDownVisibleChange}
        >
          <Button
            icon={<MoreOutlined />}
            className={classNames('more-button', {
              'more-button-active': isHover || isDropdownVisible
            })}
            type="primary"
            size="small"
          />
        </Dropdown>
      )}


      {isHover &&
        advancedFeature?.adjustVolumn.enabled &&
        advancedFeature.adjustVolumn.toggled &&
        zmClient.getSessionInfo().userId !== userId && (
          <div className={classNames('avatar-volume')}>
            <label>Volume:</label>
            <Slider marks={{ 0: '0', 100: '100' }} defaultValue={100} onChange={onSliderChange} value={volume} />
          </div>
        )} */}

        
        {/* <div className='avatar-sec'>{rand}</div> */}

        {
          (rand == 1 || rand == 3 || rand == 5 || rand == 7) && 

          <div className='avatar-sec'>
            <Canvas className="avatar-image" camera={{ fov: 20 }} shadows>
              <ambientLight intensity={0.5} />
              <pointLight position={[10, 10, 10]} color={new Color(1, 1, 0)} intensity={0.5} castShadow />
              <pointLight position={[-10, 0, 10]} color={new Color(1, 0, 0)} intensity={0.5} castShadow />
              <pointLight position={[0, 0, 10]} intensity={0.5} castShadow />
              <Avatar3 url={url2} />
            </Canvas>
          </div>
        }

        {
          (rand == 0 || rand == 2 || rand == 4 || rand == 6 || rand == 8) && 

          <div className='avatar-sec'>
            <Canvas className="avatar-image" camera={{ fov: 20 }} shadows>
              <ambientLight intensity={0.5} />
              <pointLight position={[10, 10, 10]} color={new Color(1, 1, 0)} intensity={0.5} castShadow />
              <pointLight position={[-10, 0, 10]} color={new Color(1, 0, 0)} intensity={0.5} castShadow />
              <pointLight position={[0, 0, 10]} intensity={0.5} castShadow />
              <Avatar2 url={url} />
            </Canvas>
          </div>
        }
    </div>
  );
};

export default Avatar;
